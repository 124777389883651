import {useState} from 'react';

export const useCountdown = (initial) => {
  const [seconds, setSeconds] = useState(initial);
  const [complete, setComplete] = useState(false);
  const [started, setStarted] = useState(false);

  const start = () => {
    setStarted(true);

    const id = setInterval(() => setSeconds((prev) => {
      if (prev === 0) {
        setStarted(false);
        setComplete(true);
        clearInterval(id);
      }

      return prev - 1;
    }), 1000);
  };

  const reset = () => {
    setComplete(false);
    setSeconds(initial);
  };

  return {
    seconds,
    start,
    reset,
    complete,
    started,
  };
};
