import './App.css';
import {useCallback, useEffect, useMemo, useRef, useState,} from 'react';
import {AnimatePresence, motion} from 'framer-motion';

import templateDesktop from './assets/template_desktop.png';
import templateMobile from './assets/vertical.png';
import countdownAudio from './assets/countdown.mp3';
import captureAudio from './assets/capture.mp3';

import verticalMov from './assets/vertical.mov';
import verticalWebm from './assets/vertical.webm';

import horizontalMov from './assets/horizontal.mov';
import horizontalWebm from './assets/horizontal.webm';

import {useCountdown} from './hooks/useCountdown';
import {isIos, supportsHEVCAlpha} from './utils';

const audio = new Audio(countdownAudio);

const CAPTURE_DELAY = 3;

function App() {
  const videoRef = useRef();
  const coverRef = useRef();

  const [granted, setGranted] = useState(false);
  const [ready, setReady] = useState(false);
  const {seconds, start, started, complete, reset} = useCountdown(CAPTURE_DELAY);

  const [isFlash, setFlash] = useState(false);
  const [result, setResult] = useState();

  const [size, setSize] = useState({
    w: window.innerWidth,
    h: window.innerHeight,
  });

  useEffect(() => {
    const handler = (e) => {
      setSize({
        w: e.target.innerWidth,
        h: e.target.innerHeight,
      })
    };

    window.addEventListener('resize', handler)

    return () => {
      window.removeEventListener('resize', handler)
    };
  }, []);

  const isPortrait = useMemo(() => size.h > size.w, [size]);

  useEffect(() => {
    if (complete) {
      setFlash(true);
      setTimeout(() => setFlash(false), 1000);
    }

  }, [complete, started]);

  useEffect(() => {
    if (!complete && started) {
      audio.src = countdownAudio;
      audio.volume = 1;
      audio.play();
    }

  }, [started, seconds, complete]);

  useEffect(() => {
    if (complete) {
      audio.src = captureAudio;
      audio.loop = false;
      audio.volume = 1;
      audio.play();
    }

  }, [complete, started]);

  useEffect(() => {
    if (complete) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const image = new Image();
      image.onload = () => {
        canvas.height = image.height;
        canvas.width = image.width;
        const LEFT_OFFSET = isPortrait ? 205 : 650;
        const TOP_OFFSET = isPortrait ? 572: 166;
        const USER_SIZE = isPortrait ? 550 : 550;
        context.drawImage(videoRef.current, LEFT_OFFSET, TOP_OFFSET, USER_SIZE, USER_SIZE);
        context.drawImage(image, 0, 0, image.width, image.height);
        const result = canvas.toDataURL();

        setResult(result);
      };
      image.src = isPortrait ? templateMobile : templateDesktop;
    }
  }, [complete, isPortrait]);

  const download = useCallback(() => {
    const a = document.createElement('a');
    a.href = result;
    a.download = 'PumpUpYourLife.png';
    a.click();
  }, [result]);

  const src = useMemo(() => {

    const supportsMov = isIos() || supportsHEVCAlpha();
    if (supportsMov){
     return  isPortrait ? verticalMov : horizontalMov;
    }

    return isPortrait ? verticalWebm : horizontalWebm;


  }, [isPortrait]);

  const options = {
    autoPlay: true,
    muted: true,
    playsInline: true,
  };

  return (
    <div className="container" style={{ height: size.h }}>
      <div className="compose">
        <video width="100%" {...options} src={src} muted/>
        <video ref={videoRef} className="user-camera" width="100%" playsInline/>
        <video ref={coverRef} className="cover-container" width="100%" {...options} loop src={src}/>
        <AnimatePresence>
          { complete && result && (
            <motion.img
              className="user-snapshot"
              src={result}
              alt="user-snapshot"
              exit={{ opacity: 0}}
              initial={{ opacity: 0}}
              animate={{ opacity: 1}}
              transition={{
                ease: 'easeOut',
                duration: 0.1,
                delay: 0.1
              }}
            />
          ) }
        </AnimatePresence>
      </div>
      {started && (
        <div className="countdown-container" style={{ height: size.h }}>
          <AnimatePresence>
            <motion.span
              key={seconds}
              className="countdown-value"
              exit={{y: 75, opacity: 0}}
              initial={{y: -150, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              transition={{
                ease: 'easeOut',
                duration: 0.8,
              }}
            >
              {seconds}
            </motion.span>
          </AnimatePresence>
        </div>
      )}

      <div className="actions">
        {ready && (
          <motion.div
            className="shot"
            initial={{y: 150, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            whileTap={{scale: 0.8}}
            onClick={() => {
              if (complete) {
                reset();
              }
              if (!complete && !started) {
                audio.volume = 0;
                audio.play();
                start();
              }
            }}
          >
            <span>{complete ? 'reset' : ''}</span>
          </motion.div>
        )}
        <AnimatePresence>
          {ready && complete && (
            <motion.div
              key="downloadExit"
              className="download"
              initial={{y: 150, opacity: 0}}
              animate={{
                y: 0, opacity: 1, transition: {
                  delay: 1
                }
              }}
              exit={{opacity: 0, y: 150}}
              whileTap={{scale: 0.8}}
              onClick={download}
            >
              <span>
                download
              </span>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {!granted && (
          <div className="permission-backdrop">
            <motion.div
              key="permission"
              className="permission-container"
              initial={{y: 150, opacity: 0}}
              animate={{
                y: 0, opacity: 1, transition: {
                  delay: 1
                }
              }}
              exit={{opacity: 0, y: 150}}
            >
              <h3>
                Permission needed
              </h3>
              <span>
                Please, provide us access to your camera,
                which is required for the app
              </span>
              <motion.span
                whileTap={{scale: 0.8}}
                className="permission-button"
                onClick={() => {
                  navigator.mediaDevices.getUserMedia({
                    video: {
                      width: 720,
                      height: 720
                    }
                    , audio: false
                  })
                    .then((stream) => {
                      videoRef.current.srcObject = stream;
                      videoRef.current.play();

                      setReady(true);
                      setGranted(true);

                    })
                    .catch((err) => {
                      console.log('An error occurred: ' + err);
                    });
                }}
              >
                Allow access
              </motion.span>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <div className={`flash-container ${isFlash ? 'flash' : ''}`}/>
    </div>
  );
}

export default App;
